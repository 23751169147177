import { View, Text, Platform } from 'react-native'
import React, { useState, useRef }  from "react";
import { CustomTextInput, TextButton } from "../../General/Other";
import { MEDIUM_B, BUTTON_PRIMARY  } from '../../../style'

export default function UpdateValue(p){

    
    
    const data = Platform.OS === 'web' ? p.data : p.route.params.data
    const onClickAction = Platform.OS === 'web' ? p.onClickAction : p.route.params.onClickAction
    
    const [textW, setTextW] = useState(data)
    var textInputWidth = useRef(null)

    

    
    return (
        <View>
            <Text style={MEDIUM_B}>
                {'Aggiorna il valore '}
            </Text>
            <CustomTextInput 
                ref={(ref) => textInputWidth.current = ref} 
                accept="float"
                keyboardType="numbers-and-punctuation" 
                returnKeyType='done'
                style={{padding: 5, ...MEDIUM_B}} 
                onChangeText={(t) => {
                    setTextW(t)
                }} 
                text={String(textW)}
                placeholder={'Inserisci il valore'}/>
            <TextButton 
                type={BUTTON_PRIMARY}
                onPress={() => {
                    if (Platform.OS === 'web') {
                        onClickAction(textW)
                    } else {
                        //p.navigation.setOptions({'data': textW})
                        p.onClickAction(textW)
                        p.navigation.goBack()
                    }
                         
                }}
                title={"Update".toUpperCase()}/>
        </View>
    )
}