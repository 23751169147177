import React, { useState, useContext, useEffect } from "react";
import { ScrollView, Text, View, TouchableOpacity, Image, Platform, Linking } from 'react-native'
import { UserContext } from '../../../utils/context'
import { container, BUTTON_PRIMARY, getSquareButton, BUTTON_SECONDARY, WHITE, 
    TEXT_B, WARNING_LIGHT, TITLE_B, getFont, DARK_GRAY, RED, DEFAULT_FONT, DEFAULT_FONT_LIGHT } from "../../../style";
import { generateTxt, generateTxtBordura, section_flow_reduction, section_bordura, section_input_data, section_surface, section_settings_machine, section_settings_isobus } from './utilsResults'
import { addFavorite, getOutput, getOpenPosition } from '../../../api/dcm_api'
import { TextIconButton, ShowImage, CustomModal } from '../../General/Other'
import RegulatorVRI from './RegulatorVRI'
import UpdateValue from './UpdateValue'
import CustomSegmentedControl from '../../General/CustomSegmentedControl'
import { CustomIcon } from '../../General/CustomIcon'

export default function Results(props) {

    const { machine, fertilizer, family, settings, paletta, elettric } = props
    const { languages, code, metrics, getUserApi, setMessage, user } = useContext(UserContext);
    const [outStateApi, setOutStateApi] = useState({isLoading: false, error: null, response: null});
    const [stateApi, setStateApi] = useState({isLoading: false, error: null, response: null});

    const [updateState, setUpdateState] = useState(null);

    


    useEffect(() => {



        setOutStateApi({isLoading: true, error: null, response: null})        
        getOutput(code, 
            machine.id, 
            fertilizer.id, 
            settings ? settings.width : 0, 
            settings ? settings.velocity : 0, 
            settings ? settings.quantity: 0, 
            paletta ? paletta.id : 0, 
            props.blade ? 1 : 0, 
            props.cmHeightPianta ? props.cmHeightPianta : 0 ).then( success => {
                if (success.response) {
                    setOutStateApi({isLoading: false, error: null, response: success.response})
                } else {
                    setOutStateApi({isLoading: false, error: success.error, response: null})
                }
        })
    }, [])

    const createBody = (out) => {
        
        const input_sec = generateTxt(section_input_data(machine, family, fertilizer, settings, elettric, languages, code, metrics))
        const surface = out.response ? generateTxt(section_surface(out.response, languages, code, metrics)) : ""
        const sett_machine = out.response ? generateTxt(section_settings_machine(out.response, elettric, languages, code, metrics)) : ""
        const isobus = out.response ? generateTxt(section_settings_isobus(out.response, fertilizer, settings, elettric, languages, code, metrics)) : ""
        const b_standad = out.response?.border?.standard ? generateTxtBordura(section_bordura(out.response?.border?.standard, languages["border_standard"][code], languages, code)) : ""
        const b_optional = out.response?.border?.optional ? generateTxtBordura(section_bordura(out.response?.border?.optional, "Bordura optional", languages, code)) : ""
        const reduction = out.response?.reduction?.optional ? generateTxtBordura(section_flow_reduction(out.response?.reduction?.optional, languages, code)) : ""
        const note = fertilizer.description
        const fall = out.response?.fallpoints?.fallPoints ? out.response?.fallpoints?.fallPoints : []
        var fallpoints_v = ""
        fall.forEach( f => {
            fallpoints_v = fallpoints_v + "\t\t" + f.fallPointPos + ": " + f.meters + "\n"
        })
        return input_sec + surface + sett_machine + isobus + fallpoints_v + b_standad + b_optional + reduction + note
        
    }


    return (
        <ScrollView style={{marginHorizontal: Platform.OS === 'web' ? 0 : 10}}showsVerticalScrollIndicator={false}>
            <HeaderButtons 
                    isLoadingSave={stateApi.isLoading}
                    onSaveClick={() => {
                        
                        setStateApi({isLoading: true, error: null, response: null})
                        addFavorite({ "machine": machine, "family": family, "product": fertilizer ,"settings": settings})
                            .then( s => {
                                getUserApi()
                                if (s.response){
                                    setStateApi({isLoading: false, error: null, response: s.response})
                                    setMessage({message: languages["element_save"][code], type: "SUCCESS"})
                                } else {
                                    setStateApi({isLoading: false, error: s.error, response: null})
                                    setMessage({message: languages["element_not_save"][code], type: "ERROR"})
                                }
                            })   
                    }} 
                    onShareClick={() => {
                        if (user) {
                            const body = createBody(outStateApi)
                            const subject = "Result"
                            const mittente = "dcm@spreadshit.com"
                            Linking.openURL(`mailto:${mittente}?subject=${subject}&body=${body}`)
                        } else {
                            setMessage({message: languages["element_not_save"][code], type: "ERROR"})
                        }
                        
                    }} />
            { outStateApi.response && <ResultNode notes={fertilizer.description}/>}
            <ResultSection 
                elettric={elettric} 
                data={section_input_data(machine, family, fertilizer, settings, elettric, languages, code, metrics)} 
                navigation={props.navigation} />
            { outStateApi.response && 
                    <ResultSection 
                        elettric={elettric}
                        data={section_surface(outStateApi.response, languages, code, metrics)} 
                        navigation={props.navigation} /> }
            { outStateApi.response && 
                    <ResultSection 
                        updateValueForMobile={props.updateValue}
                        newState={updateState}
                        updateRightValue={(response, sourceRow, targetRow) => {
                            setUpdateState({'sourceRow': sourceRow, 'targetRow': targetRow, 'value': response})
                        }}
                        {...props}
                        elettric={elettric}
                        data={section_settings_machine(outStateApi.response, elettric, languages, code, metrics)} 
                        navigation={props.navigation} /> }
            { outStateApi.response && elettric && 
                    <ResultSection 
                        elettric={elettric}
                        data={section_settings_isobus(outStateApi.response, fertilizer, settings, elettric, languages, code, metrics)} 
                        navigation={props.navigation} /> }
            { outStateApi.response?.border?.standard && 
                    <ResultSectionWithOption 
                        data={section_bordura(outStateApi.response?.border?.standard, 'TODO lingua (paletta special)', languages, code)} 
                        navigation={props.navigation} /> }
            { outStateApi.response?.border?.optional && 
                    <ResultSectionWithOption 
                        data={section_bordura(outStateApi.response?.border?.optional, languages["border_standard"][code], languages, code)} 
                        navigation={props.navigation} /> }
            { outStateApi.response?.reduction?.optional && 
                    <ResultSectionWithOption 
                        data={section_flow_reduction(outStateApi.response?.reduction?.optional, languages, code)} 
                        navigation={props.navigation} /> }
        </ScrollView>
    )
}

export function HeaderButtons(props){
    return (
        <View style={{flexDirection: 'row', justifyContent:'flex-end'}}>
            <TextIconButton style={[container.select_button, { marginBotton: 10, marginRight: 5 }]} 
                onPress={() => props.onSaveClick()}
                extra_style={{marginRight: 10}}
                type={BUTTON_PRIMARY}
                icon="star" title="SAVE"/>
            <TextIconButton 
                onPress={() => props.onShareClick()}
                type={BUTTON_SECONDARY}
                icon="share" title="SHARE"/>
        </View>
    )
}



export function ResultSectionWithOption(props){

    
    const [indexSegmented, setIndexSegmented] = useState(0);
    
    


    return (
        <View style={{padding: 4, marginTop: 10}}>
            <Text style={TITLE_B}>{props.data.title}</Text>
            <CustomSegmentedControl
                    values={props.data.title_segmented}
                    selectedIndex={indexSegmented}
                    onChange={(event) => {
                        setIndexSegmented(event)
                    }} />
            {
                props.data.rows.map( (r, index) => 
                    <ResultRowParent 
                        navigation={props.navigation}
                        key={index} 
                        icona={r.icona} 
                        iconaLarge={r.iconaLarge}
                        titleRow={r.titleRow}
                        rightText={r.rightText[indexSegmented]} 
                        leftText={r.leftText}  />)
            }
        </View>
    )
}



export function ResultSection(props){
        
    return (
        <View style={{ marginTop: 10}}>
            <Text style={TITLE_B}>{props.data.title}</Text>
            {
                props.data.rows.map( (r, index) => 
                    <ResultRowParent 
                        updateValueForMobile={props.updateValueForMobile}
                        newState={props.newState}
                        updateRightValue={(response, sourceRow, targetRow) => props.updateRightValue(response, sourceRow, targetRow)}
                        machine={props.machine}
                        fertilizer={props.fertilizer}
                        settings={props.settings}
                        paletta={props.paletta}
                        blade={props.blade}
                        cmHeightPianta={props.cmHeightPianta}
                        key={index} {...r} 
                        navigation={props.navigation} />)
            }
        </View>
    )
}

export function ResultRowParent(props) {

    const [dataOnLayer, setDataOnLayer] = useState(null);
    const [modalToShow, setModalToShow] = useState(false);
    const [nameModalToShow, setNameModalToShow] = useState('');

    const [updateRightValue, setUpdateRightValue] = useState(null);
    const [updateRightValueTarget, setUpdateRightValueTarget] = useState(null);

    const { code } = useContext(UserContext);

    const onClickAction = (callbackValue) => {
        setModalToShow(!modalToShow)
        setNameModalToShow('')
                            
        setUpdateRightValue(callbackValue)
        setUpdateRightValueTarget('kg_min')

        getOpenPosition(code, 
            props.machine ? props.machine.id : 0, 
            props.fertilizer ? props.fertilizer.id : 0, 
            props.settings ? props.settings.width : 0, 
            props.settings ? props.settings.velocity : 0, 
            props.settings ? props.settings.quantity: 0, 
            props.paletta ? props.paletta.id : 0, 
            props.blade ? 1 : 0, 
            props.cmHeightPianta ? props.cmHeightPianta : 0, 
            callbackValue ).then( success => {
                props.updateRightValue('TODO', props.titleRow, 'open_position')
                                //TODO gestire con luca
                                /*if (success.response) {
                                    setNewoutputState({isLoading: false, error: null, response: success.response})
                                } else {
                                    setNewoutputState({isLoading: false, error: success.error, response: null})
                                }*/
                        
                                //setNewoutputState({isLoading: false, error: null, response: "NEW OPEN POS", forIdentifyRow: "open_position"})
            })

    }

    useEffect(() => {
        
        props.updateValueForMobile && onClickAction(props.updateValueForMobile)

    }, [props.updateValueForMobile])
    
    
    return (
        <View>
            <CustomModal 
                innerComponet={<ShowImage icon={dataOnLayer}/>}
                modalVisible={modalToShow && nameModalToShow === 'ShowImage'} 
                onClose={() => {
                    setModalToShow(!modalToShow)
                    setNameModalToShow('')
                }}/>
            <CustomModal 
                innerComponet={<RegulatorVRI data={dataOnLayer}/>}
                modalVisible={modalToShow && nameModalToShow === 'RegulatorVRI'} 
                onClose={() => {
                    setModalToShow(!modalToShow)
                    setNameModalToShow('')
                }}/>
            <CustomModal 
                innerComponet={<UpdateValue 
                        data={dataOnLayer} 
                        onClickAction={(callbackValue) => {
                            onClickAction(callbackValue)
                        }}/>}
                modalVisible={modalToShow && nameModalToShow === 'UpdateValue'} 
                onClose={() => {
                    setModalToShow(!modalToShow)
                    setNameModalToShow('')
                }}/>
            <ResultRow {...props} 
                updateRightValueTarget={updateRightValueTarget}
                updateRightValue={updateRightValue}
                onClickIcon={(icon) => { 
                    
                    if (Platform.OS === 'web') {
                        setDataOnLayer(icon)
                        setModalToShow(!modalToShow)
                        setNameModalToShow('ShowImage')
                    } else {
                        
                        props.navigation && props.navigation.push('ShowImage', { image: icon })
                    }
                }}
                onClickRow={(data) => {
                    
                    if (Platform.OS === 'web') {
                        setDataOnLayer(data)
                        setModalToShow(!modalToShow)
                        setNameModalToShow('RegulatorVRI')
                    } else {
                        props.navigation && props.navigation.push('RegulatorVRI', { data: data })
                    }
                }}
                onClickActionButton={(data) => {
                    if (Platform.OS === 'web') {
                        setDataOnLayer(data)
                        setModalToShow(!modalToShow)
                        setNameModalToShow('UpdateValue')
                    } else {
                        
                        
                        //onClickAction:onClickAction
                        if (props.navigation) {
                            //props.navigation.setOptions({'clickUpdateButton': onClickAction})
                            props.navigation.push('UpdateValue', { data: data })
                            
                        } 
                    }
                }}
            />
        </View>
    )
}


export function ResultRow(props) {

    const hasIcon = (props.icona && props.icona !== '') ? true : false
    

    const getRightValue = (titleRow, rightText, newState, updateRightValue, updateRightValueTarget) => {
        if (titleRow === updateRightValueTarget) {
            return { 'originalValue': rightText, 'rightValue': updateRightValue, 'renderStyle': true }
        }
        
        if (newState) {
            
            if (newState['targetRow'] === titleRow) {
                return { 'originalValue': rightText, 'rightValue': newState['value'], 'renderStyle': true }
            } else {
                return { 'originalValue': rightText, 'rightValue': rightText, 'renderStyle': false }
            }
        } else {
            return { 'originalValue': rightText, 'rightValue': rightText, 'renderStyle': false }
        }

        
    }

    const RightView = (p) => {

        const right = getRightValue(p.titleRow, p.rightText, p.newState, p.updateRightValue, p.updateRightValueTarget)
        return (
            <>
                { 
                    right['renderStyle'] ? 
                        <View style={{flexDirection: 'row'}}>
                            <Text style={[getFont(16, DARK_GRAY, DEFAULT_FONT), {marginHorizontal: 15}]}>
                                {right['rightValue']}
                            </Text>
                            <Text style={[getFont(16, DARK_GRAY, DEFAULT_FONT), {textDecorationLine: 'line-through'}]}>
                                {right['originalValue']}
                            </Text>

                        </View>
                        : 
                        <Text style={[getFont(16, DARK_GRAY, DEFAULT_FONT), {marginHorizontal: 15}]}>
                            {right['rightValue']}
                        </Text> 
                }
            </>
        )

        
    }

    return (
        <View>
            <TouchableOpacity style={[container.row_result, {marginVertical: 3}]}>
                <TouchableOpacity 
                    onPress={() => props.isNavigation && props.onClickRow(props.data)} 
                    style={{
                        flexDirection:'row',
                        alignItems:'center'}}>
                    {  hasIcon && 
                    <TouchableOpacity onPress={() => props.onClickIcon(props.iconaLarge || props.icona)}>
                    <Image 
                        style={{'width':30,'height':30, marginRight: 7, resizeMode: 'contain'}} 
                        source={props.icona} />
                    </TouchableOpacity>}
                    <View style={ {flexDirection:'row', flex:1, justifyContent: 'space-between', alignItems: 'center'}}>
                        <Text style={getFont(16, DARK_GRAY, DEFAULT_FONT_LIGHT)}>{props.leftText || ''}</Text> 
                        <RightView {...props}/>
                    </View>
                    {/* { props.youtube && <View style={{paddingLeft: 5}}>
                        <YouTubeIcon youtubeID={props.youtube} askOpen={Platform.OS !== 'web'}/>
                    </View> } */}
                    { props.actionView && 
                        <TouchableOpacity 
                            onPress={() => {
                                props.onClickActionButton(props.rightText)
                            }}
                            style={{...getSquareButton(22, true), marginStart: 12}}>
                            <CustomIcon name="create" size={22} color={WHITE}/>
                        </TouchableOpacity>
                    }
                </TouchableOpacity>       
            </TouchableOpacity>
            { props.optionalMessage && 
                <Text style={{...TEXT_B, textAlign: 'right', color: RED}}>
                    {props.optionalMessage}
                </Text> 
            }
        </View>
    )
    
}


export function ResultNode(props){
    return (
        <View style={{padding: 14, marginTop: 5, backgroundColor: WARNING_LIGHT, borderRadius: 5}}>
            <Text style={[TEXT_B, {padding: 10}]}>
                {props.notes}
            </Text>
        </View>
    )
}


