import { View, Text, Platform } from 'react-native';
import { useContext } from 'react'
import { getFont, GREEN, TITLE_B, MEDIUM_B, DEFAULT_FONT } from "../../../style";
import { UserContext } from '../../../utils/context'

export default function AlertMessage(props) {
    //TODO lingua
    const { languages, code } = useContext(UserContext);
    return (
        <View style={{marginHorizontal: Platform.OS !== 'web' ? 10 : 0}}>
            <Text style={TITLE_B}>Hai selezionato questo fertilizzante</Text>
            <View style={{padding: 12, borderColor: GREEN, borderRadius: 5, borderWidth: 2, marginVertical: 12}}>
                <Text style={TITLE_B}>{props.fertilizer.name}</Text>
                <Text style={MEDIUM_B}>
                    {languages["vendor"][code].toUpperCase()+": " +props.fertilizer.vendor}
                </Text>
            </View>
            
            <Text style={getFont(22, GREEN, DEFAULT_FONT)}>
                Contralla bene che il produttore sia quello desiderato
            </Text>
        </View>
    )
}